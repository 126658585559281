import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

import * as styles from './Footer.module.scss';

const Footer = (props) => (
    <footer className={styles.Footer}>
        <div className={styles.SocialWrapper}>
            <div className={styles.SocialItems}>
                <a href= "https://vm.tiktok.com/ZMeyUNysh/"
                    className={styles.SocialLink}
                    target="_blank"
                    rel="noreferrer">
                    <FontAwesomeIcon icon={ faTiktok } />
                </a>
            </div>
            <div>
                <a href="https://instagram.com/quinncarson?igshid=5mhycsrs86ha"
                    className={styles.SocialLink}
                    target="_blank"
                    rel="noreferrer">
                    <FontAwesomeIcon icon={ faInstagram } />
                </a>
            </div>
            <div>
                <a href="https://youtube.com/user/quinncarson"
                className={styles.SocialLink}
                target="_blank"
                rel="noreferrer">
                <FontAwesomeIcon icon={ faYoutube } />
                </a>
            </div>
        </div>
        <small className={styles.Rights}>
            Quinn Carson Music&copy; All Rights Reserved
            {' '}{new Date().getFullYear()}
        </small>
    </footer>
);

export default Footer;