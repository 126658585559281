import React from 'react';
import Footer from '../Footer';
import favicon from '../../assets/favicon.ico';
import Helmet from 'react-helmet';

import '../../styles/reset.scss';
import * as styles from './Layout.module.scss';

const Layout = (props) => (
    
    <div>
        <Helmet>
            <link rel="icon" href={favicon} />
            <meta charSet="utf-8" />
            <title>Quinn Carson Music</title>
        </Helmet>
        <div className={styles.Layout}>
            <main>
                { props.children }
            </main>
            <Footer />
        </div>
    </div>
);

export default Layout;

